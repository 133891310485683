/*
 * @Date: 2022-06-08 14:59:51
 *
 * @LastEditTime: 2022-06-27 10:17:22
 * @FilePath: \iot-admin-ui\src\store\modules\config.ts
 */
import { Module } from "vuex";
import { ConfigState, RootStateTypes } from "/@/store/interface/index";

const configModule: Module<ConfigState, RootStateTypes> = {
  namespaced: true,
  state: {
    status: 1, // 状态等于启用的
  },
  mutations: {
    // 获取地区 区域数据
    getConfigs(state, data: ConfigState) {
      // 状态
      if (data.status) {
        state.status = data.status;
      }
    },
  },
  actions: {
    // 设置地区 区域数据 直接传递数据
    /*
		async setRegionLists({ commit,state}, data: commonState) {
			if (data && data.regionList && data.regionList.length > 0) {
				commit('getRegionLists', data);
			} else {
				const now = Math.floor(new Date().getTime() / 1000) as number;
				const apiTime = state.regionApiTime as number
				if (state.regionList.length == 0 || now - apiTime > state.regionTime) {
					getRegionList({}).then(res=> {
						const tree = getTree(res.data,{id:'id',pid:'parent_id'});
						commit('getRegionLists', { 
							regionList: tree,
							regionApiTime: now
						});
					})
				}
			}
		},
		*/
    /*
		async setRegionLists({ commit,state}, data: IotState) {
			return new Promise( (resolve,reject) => {//resolve,reject是两个系统函数，对应成功和失败
				if (data && data.regionList && data.regionList.length > 0) {
					commit('getRegionLists', data);
					resolve(data.regionList);
				} else {
					const now = Math.floor(new Date().getTime() / 1000) as number;
					const apiTime = state.regionApiTime as number
					if (state.regionList.length == 0 || now - apiTime > state.regionTime) {
						getRegionList({}).then(res=> {
							// key值
							const regionKeys = [];
							for(const i in res.data){
								regionKeys[res.data[i].id] = res.data[i];
							}
							const tree = getTree(res.data,{id:'id',pid:'parent_id'});
							commit('getRegionLists', { 
								regionList: tree,
								regionApiTime: now,
								regionKeys,
							});
							resolve({
								regionList: tree,
								regionKeys,
							});
						})
					} else {
						resolve(state.regionList);
					}
				}
			});
		},
		*/
  },
};

export default configModule;
