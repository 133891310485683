/*
 * @Date: 2022-07-04 16:23:30
 *
 * @LastEditTime: 2022-07-05 09:35:49
 * @FilePath: \iot-admin-ui\src\utils\commonDirective.ts
 */
import { ElMessage } from "element-plus";
import type { App } from "vue";

// 获取到表单对象
function getInput(el: HTMLElement) {
  let inputEle;
  if (el.tagName !== "INPUT") {
    inputEle = el.querySelector("input");
  } else {
    inputEle = el;
  }
  return inputEle;
}

/**
 * 用户自定义指令 - 全局
 * @directive 过滤前后空格（v-trim）
 * @directive 复制字符串 v-copy

 */
export function commonDirective(app: App) {
  // 过滤前后空格的指令 v-trim
  app.directive("trim", {
    mounted(el) {
      const inputEle = getInput(el);
      const handler = function (event: any) {
        event.stopPropagation();
        const newVal = event.target.value.trim();
        if (event.target.value !== newVal) {
          event.target.value = newVal;
          dispatchEvent(inputEle, "input");
        }
      };
      // 回车后过滤空格
      const keydown = function (event: any) {
        if (event.keyCode === 13) {
          const newVal = event.target.value.trim();
          if (event.target.value !== newVal) {
            event.target.value = newVal;
            dispatchEvent(inputEle, "input");
          }
        }
      };
      el.inputEle = inputEle as HTMLElement;
      el._blurHandler = handler;
      el._keyHandler = keydown;
      if (inputEle) {
        inputEle.addEventListener("blur", handler);
        inputEle.addEventListener("keydown", keydown);
      }
    },
    unmounted(el) {
      const { inputEle } = el;
      inputEle.removeEventListener("blur", el._blurHandler);
      inputEle.removeEventListener("keydown", el._keyHandler);
    },
  });

  // 复制前后字符串
  app.directive("copy", {
    mounted: function (el, { value }) {
      // console.log(el,el.childNodes,el.innerText,value)
      if (value == undefined) {
        value = el.innerText;
      }
      el.$value = value;

      // el控件定义 onclick 事件
      el.onclick = (event) => {
        event.stopPropagation();
        if (!el.$value) {
          // 值为空的时候，给出提示。可根据项目UI仔细设计
          return;
        }
        // 动态创建 textarea 标签
        const textarea = document.createElement("textarea");
        // 将该 textarea 设为 readonly 防止 iOS 下自动唤起键盘，同时将 textarea 移出可视区域
        textarea.readOnly = "readonly";
        textarea.style.position = "absolute";
        textarea.style.left = "-9999px";
        // 将要 copy 的值赋给 textarea 标签的 value 属性
        // textarea.value = el.$value
        textarea.value = el.$value || value;
        // 将 textarea 插入到 body 中
        document.body.appendChild(textarea);
        // 选中值并复制
        textarea.select();
        const result = document.execCommand("Copy");
        if (result) {
          // console.log('复制成功') // 可根据项目UI仔细设计
          ElMessage.success(
            "复制成功 " + textarea.value.substring(0, 50) + "..."
          );
        }
        document.body.removeChild(textarea);
      };
      // 绑定点击事件，就是所谓的一键 copy 啦
      el.addEventListener("click", el.handler);
    },
    // 当传进来的值更新的时候触发
    beforeUpdate(el, { value }) {
      // console.log("更新值")
      if (value == undefined) {
        value = el.innerText;
      }
      el.$value = value;
    },
    // 指令与元素解绑的时候，移除事件绑定
    unmounted(el) {
      el.removeEventListener("click", el.handler);
    },
  });
}
