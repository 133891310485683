/*
 * @Date: 2022-06-08 14:59:51
 *
 * @LastEditTime: 2022-08-17 17:29:54
 * @FilePath: \iot-admin-ui\src\store\modules\icon.ts
 */
import { Module } from "vuex";
import { IconState, RootStateTypes } from "/@/store/interface/index";

const iconModule: Module<IconState, RootStateTypes> = {
  namespaced: true,
  state: {
    aliIcon: [
      // 阿里云图标
      { name: "温控器", icon: "icon-tubiao-wenkongqi" },
      { name: "温控阀", icon: "icon-wenkongfa" },
      { name: "温控器1", icon: "icon-wenkongqi" },
      { name: "设备", icon: "icon-shebeijiance_o" },
      { name: "设备1", icon: "icon-shebeisousuo_o" },
      { name: "设备2", icon: "icon-shebeidadian_o" },
      { name: "设备", icon: "icon-ac-tio" },
      { name: "设备空调", icon: "icon-ac-tpc" },
      { name: "控制器", icon: "icon-kongzhiqi" },
      { name: "空调", icon: "icon-nuantongkongtiao" },
      { name: "设备-温控器", icon: "icon-hekriconshebeizhuijiawenkongqi" },
      { name: "画板-1", icon: "icon-a-huaban1" },
    ],
  },
  mutations: {
    // 获取 区域数据
    getIcons(state, data: IconState) {
      // 状态
      if (data.aliIcon) {
        state.aliIcon = data.aliIcon;
      }
    },
  },
  actions: {
    // 设置地区 区域数据 直接传递数据
    /*
		async setRegionLists({ commit,state}, data: commonState) {
			if (data && data.regionList && data.regionList.length > 0) {
				commit('getRegionLists', data);
			} else {
				const now = Math.floor(new Date().getTime() / 1000) as number;
				const apiTime = state.regionApiTime as number
				if (state.regionList.length == 0 || now - apiTime > state.regionTime) {
					getRegionList({}).then(res=> {
						const tree = getTree(res.data,{id:'id',pid:'parent_id'});
						commit('getRegionLists', { 
							regionList: tree,
							regionApiTime: now
						});
					})
				}
			}
		},
		*/
    /*
		async setRegionLists({ commit,state}, data: IotState) {
			return new Promise( (resolve,reject) => {//resolve,reject是两个系统函数，对应成功和失败
				if (data && data.regionList && data.regionList.length > 0) {
					commit('getRegionLists', data);
					resolve(data.regionList);
				} else {
					const now = Math.floor(new Date().getTime() / 1000) as number;
					const apiTime = state.regionApiTime as number
					if (state.regionList.length == 0 || now - apiTime > state.regionTime) {
						getRegionList({}).then(res=> {
							// key值
							const regionKeys = [];
							for(const i in res.data){
								regionKeys[res.data[i].id] = res.data[i];
							}
							const tree = getTree(res.data,{id:'id',pid:'parent_id'});
							commit('getRegionLists', { 
								regionList: tree,
								regionApiTime: now,
								regionKeys,
							});
							resolve({
								regionList: tree,
								regionKeys,
							});
						})
					} else {
						resolve(state.regionList);
					}
				}
			});
		},
		*/
  },
};

export default iconModule;
