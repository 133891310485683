// 定义内容
export default {
  formI18nLabel: {
    name: "姓名",
    email: "用戶歸屬部門",
    autograph: "登入帳戶名",
  },
  formI18nPlaceholder: {
    name: "請輸入姓名",
    email: "請輸入用戶歸屬部門",
    autograph: "請輸入登入帳戶名",
  },
};
