<template>
  <slot v-if="show" />
</template>

<script setup lang="ts">
import { computed } from "vue";
import { Session } from "/@/utils/storage";

const { auth, reverseShow } = defineProps<{
  auth?: string;
  reverseShow?: boolean; // 反向显示：为不含权限的用户显示组件
}>();

const show = computed(() => {
  // 判断是否有权限
  const permissions = Session.get("permissions");
  const allPermissions = "*/*/*";
  const isSuperAdmin = permissions.indexOf(allPermissions) >= 0;

  if (isSuperAdmin) {
      // 超级管理员跳过权限判断
      return reverseShow === true ? false : true;
  } else {
      return reverseShow === true
          ? !permissions.includes(auth)
          : permissions.includes(auth);
  }
});
</script>
