/*
 * @Date: 2022-06-06 09:10:22
 *
 * @LastEditTime: 2022-09-09 12:58:53
 * @FilePath: \iot-admin-ui\src\utils\request.ts
 */
import axios from "axios";
import { ElMessage, ElMessageBox } from "element-plus";
import { Local, Session } from "/@/utils/storage";
import qs from "qs";
import md5 from "md5";
import { configure } from "nprogress";

//世界时间转换标准
import { date as dateFun } from "/@/utils/filters";

const loadingTime = 500; // 超过 400 ms就展示

// 配置新建一个 axios 实例
const service = axios.create({
  baseURL: import.meta.env.VITE_API_URL as any,
  timeout: 50000,
  headers: { "Content-Type": "application/json" },
});

// 添加请求拦截器
service.interceptors.request.use(
  (config) => {
    // 在发送请求之前做些什么 token
    if (Session.get("token")) {
      (<any>config.headers).common["Authorization"] = `Bearer ${Session.get(
        "token"
      )}`;
    }

    const date = new Date();
    const request_time = date.getTime();
    return { ...config, request_time };
  },
  (error) => {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器
service.interceptors.response.use(
  (response: any) => {
    // 对响应数据做点什么
    const res = response.data;
    const code = response.data.code;

    // 求出响应接口时间
    const date = new Date();
    const res_time = date.getTime();
    if (response.config.request_time) {
      const res_api_time = res_time - response.config.request_time;
      const out_time =
        loadingTime > res_api_time ? loadingTime - res_api_time : 1;
      res.res_api_time = res_api_time;
      res.out_time = out_time;
    }

    // 设置缓存判断
    if (response.config.method == "get") {
      res.api_time = Math.floor(res_time / 1000);
      res.api_date = date.toLocaleString();
      // const key = response.config.url + '?' + qs.stringify(response.config.params);
      // Session.set(key, res);
      // Session.set(key + "###api_time", res.api_time);
    }

    if (code == 401) {
      ElMessageBox.alert("登录状态已过期，请重新登录", "提示", {
        confirmButtonText: "确定",
      })
        .then(() => {
          Session.clear(); // 清除浏览器全部临时缓存
          window.location.href = "/"; // 去登录页
        })
        .catch(() => {});
    } else if (code !== 1) {
      let msg = res.message || res.msg;
      if (code == 422) {
        for (const i in res.detail) {
          msg +=
            "字段:" +
            res.detail[i].field +
            " " +
            res.detail[i].code +
            " " +
            res.detail[i].message +
            "\n";
        }
      }
      ElMessage.error(msg);
      return Promise.reject(new Error(res.message));
    } else {
      // 进行时间戳判断
      if (res.data && res.data.list) {
        for (const i in res.data.list) {
          if (!res.data.list[i].created_at) {
            continue;
          }
          //创建时间
          res.data.list[i].created_at = dateFun(res.data.list[i].created_at);
          //最后更新时间
          // res.data.list[i].deviceData.updated_at = dateFun(res.data.list[i].deviceData.updated_at)
          //最后上线时间
          // res.data.list[i].deviceData.last_at = dateFun(res.data.list[i].deviceData.last_at)
        }
      }
      return res;
    }
  },
  (error) => {
    // 对响应错误做点什么
    if (error.message.indexOf("timeout") != -1) {
      ElMessage.error("网络超时");
    } else if (error.message == "Network Error") {
      ElMessage.error("网络连接错误");
    } else if (error.message.indexOf("status code 401") != -1) {
      ElMessageBox.alert("登录状态已过期，请重新登录", "提示", {
        confirmButtonText: "确定",
      })
        .then(() => {
          Session.clear(); // 清除浏览器全部临时缓存
          window.location.href = "/"; // 去登录页
        })
        .catch(() => {});
    } else {
      if (error.response.data) ElMessage.error(error.response.statusText);
      else ElMessage.error("接口路径找不到");
    }
    return Promise.reject(error);
  }
);

// 导出 axios 实例
// export default service;
export default (obj: any) => {
  const apiobj = {
    url: obj.url,
    method: obj.method,
  };

  // 获取到 缓存的秒
  if (obj.cache != undefined && typeof obj.cache == "number") {
    // 获取到 日期
    const date = new Date();
    const api_time = Math.floor(date.getTime() / 1000);
    const key = apiobj.url + "?" + qs.stringify(obj.params);
    // 获取到缓存的时间
    const last_api_time = Session.get(key + "###api_time");
    if (last_api_time && api_time - last_api_time <= obj.cache) {
      const list = Session.get(key);
      return new Promise((resolve, reject) => {
        resolve(list);
        if (!list) {
          reject();
        }
      });
    }
  }

  if (obj.method == "get" || obj.method == "GET") {
    apiobj.params = obj.params;
  } else {
    apiobj.data = obj.data;
  }

  // 如果是post 请求
  if (obj.method == "post" || obj.method == "POST") {
    const urls = obj.url.split("/");
    if (urls[urls.length - 1] == "update" || urls[urls.length - 1] == "edit") {
      if (obj.data) {
        if (obj.data.created_at != undefined) {
          delete obj.data.created_at;
        }
        if (obj.data.updated_at != undefined) {
          delete obj.data.updated_at;
        }
      }
    }
  }

  return new Promise((resolve, reject) => {
    service(apiobj)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
