/*
 * @Date: 2022-06-08 17:23:25
 *
 * @LastEditTime: 2023-06-13 18:50:17
 * @FilePath: \iot-admin-ui\src\main.ts
 */
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { store, key } from "./store";
import { directive } from "/@/utils/directive";
import { i18n } from "/@/i18n/index";
import other from "/@/utils/other";

import ElementPlus from "element-plus";
import zhCN from "element-plus/dist/locale/zh-cn.mjs" //引入中文
// 暗黑模式css
import "element-plus/theme-chalk/dark/css-vars.css";
import "element-plus/dist/index.css";
import "/@/theme/index.scss";
import mitt from "mitt";

import "./polyfills.ts";

/*
避免强类型检查的错误
Object.keys(filters).forEach((key: string) => {
    Vue.filter(key, (filters as any)[key]);
});
*/

// 阿里图标
// import "/@/assets/icon/iconfont.css";

// 引入腾讯低代码编辑器
// import '@tmagic/editor/dist/style.css';
// import '@tmagic/form/dist/style.css';

// import TMagicEditor from '@tmagic/editor';
// import TMagicForm from '@tmagic/form';

// import MagicEditor from "@tmagic/editor";
// import MagicForm from "@tmagic/form";

// import TMagicEditor from '@tmagic/editor';
// import TMagicForm from '@tmagic/form';

// console.log(TMagicEditor)
// console.log(TMagicForm)

// import "@tmagic/editor/dist/style.css";
// import "@tmagic/form/dist/style.css";

import VueGridLayout from "vue-grid-layout";
import { getUpFileUrl, handleTree, selectDictLabel } from "/@/utils/gfast";
import { useDict } from "/@/api/system/dict/data";
import locale from "element-plus/es/locale/lang/zh-cn";
// 分页组件
import pagination from "/@/components/pagination/index.vue";
import "virtual:windi.css";

// json库
// import JsonViewer from "vue3-json-viewer"

// 引入标尺
import "vue3-sketch-ruler/lib/style.css";

import "animate.css/animate.min.css";
// import JsonEditorVue from 'json-editor-vue3'

// 引入初始化的执行操作
import init from "/@/utils/init";

// import '@amis/sdk/sdk.js';
// import '@amis/lib/themes/default.css';

/*
-- 注释 百度低代码
// 引入 script标签 自行添加 百度编辑器
var script = document.createElement("script");
script.type = "text/javascript";
script.src = "../public/lib/amis/sdk/sdk.js";
document.body.appendChild(script);
 
// 引入 css样式文件 自行添加 百度编辑器
var css = document.createElement("link");
css.rel = "stylesheet";
css.href = "../public/lib/amis/lib/themes/default.css";
document.body.appendChild(css);
*/

// 引入 pinia
import { createPinia } from "pinia";

// 权限组件
import auth from "/@/components/auth/auth.vue";

const app = createApp(App);

const pinia = createPinia();

// 去除报警信息
app.config.warnHandler = () => null;

directive(app);
other.elSvg(app);

app.component("pagination", pagination);

app.component("auth", auth);

// 引入过滤器
// filters(app);

app
  .use(router)
  .use(store, key)
  .use(ElementPlus, { size: "default", locale: zhCN  })
  .use(i18n)
  .use(pinia)
  .use(VueGridLayout)
  .mount("#app");

// 全局挂载
app.config.globalProperties.getUpFileUrl = getUpFileUrl;
app.config.globalProperties.handleTree = handleTree;
app.config.globalProperties.useDict = useDict;
app.config.globalProperties.selectDictLabel = selectDictLabel;
app.config.globalProperties.mittBus = mitt();

// 执行
init();
