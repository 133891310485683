/*
 * @Date: 2022-06-06 09:10:22
 *
 * @LastEditTime: 2022-06-07 14:47:15
 * @FilePath: \gfast-ui\src\api\system\dict\data.ts
 */
import request from "/@/utils/request";
import { ref, toRefs, ToRefs } from "vue";
// 根据字典类型查询字典数据信息
export function getDicts(
  dictType: string,
  defaultValue?: string
): Promise<any> {
  let dv = defaultValue ?? "";
  let params = {
    key: dictType,
    defaultValue: dv,
  };
  return request({
    url: "/admin/system/dict-data/list",
    method: "get",
    params: params,
  });
}

/**
 * 获取字典数据
 */
export function useDict(...args: string[]): ToRefs<any> {
  const res: any = ref({});
  args.forEach((d: string) => {
    res.value[d] = [];
    getDicts(d).then((resp) => {
      res.value[d] = resp.data.list.map((p: any) => ({
        label: p.name,
        value: p.value,
        isDefault: p.is_default,
      }));
    });
  });
  return toRefs(res.value);
}

export function getDataList(query: Object) {
  return request({
    // url: '/api/v1/system/dict/data/list',
    url: "/admin/system/dict-data/list",
    method: "get",
    params: query,
  });
}

export function getData(id: number | string) {
  return request({
    // url: '/api/v1/system/dict/data/get',
    url: "/admin/system/dict-data/show",
    method: "get",
    params: { id },
  });
}

export function addData(data: any) {
  return request({
    url: "/admin/system/dict-data/create",
    method: "post",
    data: data,
  });
}

export function editData(data: any) {
  return request({
    url: "/admin/system/dict-data/update",
    method: "post",
    data: data,
  });
}

export function deleteData(ids: (number | string)[]) {
  return request({
    url: "/admin/system/dict-data/delete",
    method: "post",
    data: { id: ids[0] },
  });
}
