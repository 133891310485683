/*
 * @Date: 2022-06-08 17:23:25
 *
 * @LastEditTime: 2022-09-24 09:41:06
 * @FilePath: \iot-admin-ui\src\utils\setIconfont.ts
 */

import { Local } from "./storage";

// 引入 阿里图标库
const aliIotCdnCss = import.meta.env.VITE_ALI_ICON_CSS as string;

// 第三方 js url
const jsCdnUrlList: Array<string> = [];

// 动态批量设置字体图标
export function setCssCdn() {
  const aliIconCssApi = Local.get("aliIconCss");

  // 字体图标 url
  const cssCdnUrlList: Array<string> = [
    "//at.alicdn.com/t/font_2298093_y6u00apwst.css",
    "//netdna.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css",
    aliIconCssApi ? aliIconCssApi : aliIotCdnCss,
    "https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css",
  ];
  if (cssCdnUrlList.length <= 0) return false;

  cssCdnUrlList.map((v) => {
    let link = document.createElement("link");
    link.rel = "stylesheet";
    link.href = v;
    link.crossOrigin = "anonymous";
    document.getElementsByTagName("head")[0].appendChild(link);
  });
}

// 动态批量设置第三方js
export function setJsCdn() {
  if (jsCdnUrlList.length <= 0) return false;
  jsCdnUrlList.map((v) => {
    let link = document.createElement("script");
    link.src = v;
    document.body.appendChild(link);
  });
}

/**
 * 批量设置字体图标、动态js
 * @method cssCdn 动态批量设置字体图标
 * @method jsCdn 动态批量设置第三方js
 */
const setIntroduction = {
  // 设置css
  cssCdn: () => {
    setTimeout(() => {
      setCssCdn();
    }, 350);
  },
  // 设置js
  jsCdn: () => {
    setJsCdn();
  },
};

/**
 * @name: 获取到 物联网iot cdn的图标
 * @return {*}
 *
 * @Date: 2022-08-18 12:14:22
 * @LastEditTime: Do not edit
 */
export function getAliIotCdn() {
  const aliIconCssApi = Local.get("aliIconCss");
  return aliIconCssApi ? aliIconCssApi : aliIotCdnCss;
}

// 导出函数方法
export default setIntroduction;
