<!--
 * @Date: 2022-06-08 17:23:25
 *  
 * @LastEditTime: 2022-08-31 21:20:39
 * @FilePath: \iot-admin-ui\src\components\svgIcon\index.vue
-->

<template>
  <!-- el-icon 0 -->
  <template v-if="type == 0">
    <el-icon :style="style">
      <component :is="iconName"></component>
    </el-icon>
  </template>
  <!-- 阿里云图标 1 -->
  <template v-else-if="type == 1">
    <i
      :key="id"
      :class="aliIconName"
      :style="'display:inline-block;' + style"
    ></i>
  </template>
  <!-- 图片 2 -->
  <template v-else>
    <img :key="id" :src="imgUrl" :style="'display:inline-block;' + imgStyle" />
  </template>
</template>
<script lang="ts">
// 渲染函数：https://v3.cn.vuejs.org/guide/render-function.html
import {
  h,
  resolveComponent,
  reactive,
  toRefs,
  onBeforeUpdate,
  onMounted,
} from "vue";

// 定义接口来定义对象的类型
interface SvgIconProps {
  name: string;
  size: number;
  color: string;
}

export default {
  name: "svgIcon",
  props: {
    // svg 图标组件名字
    name: {
      type: String,
      default: "",
    },
    // svg 大小
    size: {
      type: Number,
      default: () => 14,
    },
    // svg 颜色
    color: {
      type: String,
    },
  },
  setup(props: SvgIconProps) {
    let name = props.name;

    const id = Date.now() + name;

    // 定义变量
    const linesString: any[] = [
      "https",
      "http",
      "/src",
      "/assets",
      import.meta.env.VITE_PUBLIC_PATH,
    ];
    const onLineStyle: string = `font-size: ${props.size}px;color: ${props.color}`;
    const localsStyle: string = `width: ${props.size}px;height: ${props.size}px`;
    const eleSetStyle = { id, class: "el-icon", style: onLineStyle };

    let type = 0; // 0 el-icon 1 图片和网址资源   2 阿里云图标
    let eleIcon = null;
    let iconName = "Search";

    const state = reactive({
      eleIcon,
      iconName, // el-icon 动态组件

      aliIconName: name, // 阿里云图标

      style: onLineStyle, // 图标 样式

      imgStyle: localsStyle, // 图片 样式
      imgUrl: props.name, // 图片url
      type, // 类型
      id,
      initFlag: true, // 更新状态
    });

    /**
     * 初始化数据
     */
    const init = () => {
      // console.log(state.initFlag ? '执行' : "返回");
      if (!state.initFlag) {
        return false;
      }
      if (props.name?.startsWith("ele-")) {
        state.type = 0;
        state.iconName = props.name;
        // eleIcon = h('i', eleSetStyle, [props.name === 'ele-' ? '' : h(resolveComponent(props.name))])
        // console.log(eleIcon, 22222);
      } else if (linesString.find((str) => props.name?.startsWith(str))) {
        state.type = 2;
      } else {
        state.type = 1;
      }

      if (
        props.name &&
        (props.name.trim().indexOf("icon") == 0 ||
          props.name.indexOf("iconfont") != -1)
      ) {
        state.aliIconName =
          (props.name.trim().indexOf("iconfont") != 0 ? "iconfont " : "") +
          props.name.toString();
      }
      state.initFlag = false;
    };

    init();

    // 数据更新时
    onBeforeUpdate(() => {
      state.initFlag = true;
      // console.log('更新数据中...');
      init();
    });

    // 逻辑判断
    // if (props.name?.startsWith('ele-')) return () => h('i', eleSetStyle, [props.name === 'ele-' ? '' : h(resolveComponent(props.name))]);
    // else if (linesString.find((str) => props.name?.startsWith(str))) return () => h('img', { src: props.name, style: localsStyle });
    // else return () => h('i', { id, class: name ?? props.name, style: onLineStyle });

    return {
      ...toRefs(state),
    };
  },
};
</script>
