import { getGloabConfigApi } from "../api/login";
import { Local } from "./storage";

/**
 * 初始化 网站
 */
export default function init() {
  // 存储网站配置
  getGloabConfigApi()
    .then((res: any) => {
      let obj: any = {};
      if (res.data) {
        obj = res.data;
      }

      let aliIconCss = "";
      if (obj.aliIconCss && obj.aliIconCss.url) {
        aliIconCss = obj.aliIconCss.url;
      }
      Local.set("aliIconCss", aliIconCss);
      Local.set("gloab_config", JSON.stringify(obj));
    })
    .catch(() => {
      Local.set("gloab_config", JSON.stringify({}));
      Local.set("aliIconCss", "");
    });
}
