/*
 * @Date: 2022-06-17 09:44:51
 *
 * @LastEditTime: 2022-09-01 09:10:19
 * @FilePath: \iot-admin-ui\src\utils\filters.ts
 */

export function date(date_str: string | number, str = " ") {
  if (!date_str || date_str == undefined) {
    return "";
  }
  const d = new Date(date_str);
  const h = d.getHours();
  // d.toLocaleTimeString().toString()
  return (
    d.toLocaleDateString().toString() +
    str +
    fillStr(d.getHours()) +
    ":" +
    fillStr(d.getMinutes()) +
    ":" +
    fillStr(d.getSeconds())
  ); /* toLocaleString() 原先  修改后 toLocaleDateString() */
}

export function MonthDate(date_str: string | number, str = " ") {
  if (!date_str || date_str == undefined) {
    return "";
  }
  const d = new Date(date_str);
  const h = d.getHours();
  const m = d.getMonth();
  const day = d.getDate();
  // d.toLocaleTimeString().toString()
  return (
    m -
    0 +
    1 +
    "/" +
    day +
    str +
    fillStr(d.getHours()) +
    ":" +
    fillStr(d.getMinutes()) +
    ":" +
    fillStr(d.getSeconds())
  ); /* toLocaleString() 原先  修改后 toLocaleDateString() */
}

// 填充字符串
const fillStr = (str: any) => {
  return str - 0 < 10 ? "0" + str : str;
};

// 日期转换
export function createdAt(...args: any) {
  // return new Date(args[2]).toLocaleDateString()
  return date(args[2]);
}

// 兼容 filter
export function filter(value: any, filter = "") {
  return value;
}

/**
 * @name: 绑定 过滤属性
 * @param {any} value
 * @param {*} filter
 * @return {*}
 *
 * @Date: 2022-07-09 09:38:28
 * @LastEditTime: Do not edit
 */
/*
 const filters = function (app:App) {
	// 注册全局过滤器
	app.filter?.('dateFilter' as string, date);

	// 注册全局过滤器
	app.filter?.('createdAtFilter' as string, createdAt);
}
*/
