/*
 * @Date: 2022-06-06 09:10:22
 *
 * @LastEditTime: 2023-10-24 14:46:25
 * @FilePath: \gfast-ui\src\api\login\index.ts
 */
import request from "/@/utils/request";

/**
 * 登录api接口集合
 * @method signIn 用户登录
 */
export function login(params: object) {
  return request({
    // url: '/api/v1/system/login',
    url: "/admin/system/user-access/login",
    method: "post",
    data: params,
  });
}

/**
 * 获取验证码
 */
export function captcha() {
  return request({
    // url:"/api/v1/pub/captcha/get",
    url: "/admin/system/user-access/captcha",
    method: "get",
  });
}

/**
 * 获取到手机验证码
 */
export function getCaptchaPhoneApi(phone: string) {
  return request({
    // url:"/api/v1/pub/captcha/get",
    url: "/admin/system/user-access/captcha-phone",
    method: "post",
    data: {
      phone,
    },
  });
}

/**
 * 手机号码登录
 */
export function loginPhoneApi(data: object) {
  return request({
    url: "/admin/system/user-access/login-phone",
    method: "post",
    data,
  });
}

/**
 * 退出登录
 */
export function signOut(params: object) {
  return request({
    url: "/api/v1/user/signOut",
    method: "post",
    data: params,
  });
}

/**
 * 获取到  网站配置
 * @method
 */
export function getGloabConfigApi() {
  return request({
    // url: '/api/v1/system/login',
    url: "/admin/system/gloab/config",
    method: "get",
  });
}


/**
 * 获取用户信息
 */
export function getUserInfoApi(params = {}){
	return request({
		url: "/admin/system/user-access/info",
		method:"get",
		params
	})
}