/*
 * @Date: 2022-06-06 09:10:22
 *
 * @LastEditTime: 2023-10-24 17:51:10
 * @FilePath: \gfast-ui\src\api\system\menu\index.ts
 */
import request from "/@/utils/request";

export function getMenuList(query: Object) {
  return request({
    // url: '/api/v1/system/menu/list',
    url: "/admin/system/menu/list",
    method: "get",
    params: query,
  });
}

export function getUserMenus() {
  return request({
    // url: '/api/v1/system/user/getUserMenus',
    url: "/admin/system/user-access/info",
    method: "get",
  });
}

export function getMenuParams() {
  return request({
    // url: '/api/v1/system/menu/getParams',
    url: "/admin/system/menu/getParams",
    method: "get",
  });
}

export function addMenu(data: Object) {
  return request({
    url: "/admin/system/menu/create",
    method: "post",
    data: data,
  });
}

export function getMenuInfo(id: number | string) {
  return request({
    url: "/admin/system/menu/show",
    method: "get",
    params: { id },
  });
}

export function updateMenu(data: Object) {
  return request({
    url: "/admin/system/menu/update",
    method: "post",
    data: data,
  });
}

// 复制
export function copyMenuApi(data: Object) {
  return request({
    url: "/admin/system/menu/copy",
    method: "post",
    data: data,
  });
}


// 批量创建
export function batchCreateMenuApi(data: Object) {
  return request({
    url: "/admin/system/menu/batch-create",
    method: "post",
    data: data,
  });
}

// 删除菜单
export function delMenu(id: number | String) {
  return request({
    url: "/admin/system/menu/delete",
    method: "post",
    data: { id },
  });
}
