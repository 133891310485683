// 定义内容
export default {
  label: {
    one1: "用戶名登入",
    two2: "手機號登入",
  },
  link: {
    one3: "協力廠商登入",
    two4: "友情連結",
  },
  account: {
    accountPlaceholder1: "用戶名admin或不輸均為common",
    accountPlaceholder2: "密碼：123456",
    accountPlaceholder3: "請輸入驗證碼",
    accountBtnText: "登入",
  },
  mobile: {
    placeholder1: "請輸入手機號",
    placeholder2: "請輸入驗證碼",
    codeText: "獲取驗證碼",
    btnText: "登入",
    msgText:
      "* 溫馨提示：建議使用穀歌、Microsoft Edge，版本79.0.1072.62及以上瀏覽器，360瀏覽器請使用極速模式",
  },
  scan: {
    text: "打開手機掃一掃，快速登錄/注册",
  },
  signInText: "歡迎回來！",
};
