/*
 * @Date: 2022-06-08 14:59:51
 *
 * @LastEditTime: 2022-08-16 10:48:22
 * @FilePath: \iot-admin-ui\src\store\modules\iot.ts
 */
import { Module } from "vuex";
import { IotState, RootStateTypes } from "/@/store/interface/index";

import { Session } from "/@/utils/storage";

const iotModule: Module<IotState, RootStateTypes> = {
  namespaced: true,
  state: {
    projectId: "62a2ded851a09541685b3949", // 项目id
    // 62a2ded851a09541685b3949 华工
    // 62bbd28e723d784a8c2fc12d 测试项目
    projectObj: { name: "华工" }, // 项目信息
    switchProject: false, // 是否切换项目
  },
  mutations: {
    // 获取地区 区域数据
    getProjects(state, data: IotState) {
      // 项目id
      if (data.projectId) {
        state.projectId = data.projectId;
      }

      // 项目对象
      if (data.projectObj) {
        state.projectObj = data.projectObj;
      }

      // 是否-切换项目
      if (data.switchProject) {
        state.switchProject = data.switchProject;
      }
    },
  },
  actions: {
    // 设置地区 区域数据 直接传递数据
    /*
		async setRegionLists({ commit,state}, data: commonState) {
			if (data && data.regionList && data.regionList.length > 0) {
				commit('getRegionLists', data);
			} else {
				const now = Math.floor(new Date().getTime() / 1000) as number;
				const apiTime = state.regionApiTime as number
				if (state.regionList.length == 0 || now - apiTime > state.regionTime) {
					getRegionList({}).then(res=> {
						const tree = getTree(res.data,{id:'id',pid:'parent_id'});
						commit('getRegionLists', { 
							regionList: tree,
							regionApiTime: now
						});
					})
				}
			}
		},
		*/
    /*
		async setRegionLists({ commit,state}, data: IotState) {
			return new Promise( (resolve,reject) => {//resolve,reject是两个系统函数，对应成功和失败
				if (data && data.regionList && data.regionList.length > 0) {
					commit('getRegionLists', data);
					resolve(data.regionList);
				} else {
					const now = Math.floor(new Date().getTime() / 1000) as number;
					const apiTime = state.regionApiTime as number
					if (state.regionList.length == 0 || now - apiTime > state.regionTime) {
						getRegionList({}).then(res=> {
							// key值
							const regionKeys = [];
							for(const i in res.data){
								regionKeys[res.data[i].id] = res.data[i];
							}
							const tree = getTree(res.data,{id:'id',pid:'parent_id'});
							commit('getRegionLists', { 
								regionList: tree,
								regionApiTime: now,
								regionKeys,
							});
							resolve({
								regionList: tree,
								regionKeys,
							});
						})
					} else {
						resolve(state.regionList);
					}
				}
			});
		},
		*/
  },
};

export default iotModule;
