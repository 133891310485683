/**
 * @name: 获取树形菜单
 * @param {*} data
 * @return {*}
 *
 * @Date: 2022-06-06 11:50:00
 * @LastEditTime: Do not edit
 */
export function getTree(data, { id = "id", pid = "pid" } = {}) {
  // 删除 所有 children,以防止多次调用
  data.forEach(function (item) {
    delete item.children;
  });

  // 将数据存储为 以 id 为 KEY 的 map 索引数据列
  var map = {};
  data.forEach(function (item) {
    map[item[id]] = item;
  });
  //        console.log(map);
  var val = [];
  data.forEach(function (item) {
    // 以当前遍历项，的pid,去map对象中找到索引的id
    var parent = map[item[pid]];
    // 好绕啊，如果找到索引，那么说明此项不在顶级当中,那么需要把此项添加到，他对应的父级中
    if (parent) {
      (parent.children || (parent.children = [])).push(item);
    } else {
      //如果没有在map中找到对应的索引ID,那么直接把 当前的item添加到 val结果集中，作为顶级
      val.push(item);
    }
  });
  return val;
}

/**
 * @name: 转换菜单列表数据
 * @param {*} list
 * @return {*}
 *
 * @Date: 2022-06-06 12:03:30
 * @LastEditTime: Do not edit
 */
export function menuData(list) {
  for (const i in list) {
    const item = list[i];
    list[i].meta = {
      icon: item.icon,
      isAffix: item.isAffix == 1,
      isHide: item.isHide == 1,
      isIframe: item.isIframe == 1,
      isKeepAlive: item.is_cached == 1,
      isLink: item.is_link,
      title: item.title,
    };
  }
  return list;
}

/**
 * @name: 判断初始化数据 和 表单数据 是否发生改变
 * @param {*} initData
 * @param {*} ruleForm
 * @return {*}
 *
 * @Date: 2022-06-06 17:56:51
 * @LastEditTime: Do not edit
 */
export function getChangeFormData(initData, ruleForm, id = "id") {
  const update = {};
  let updateBool = false;
  for (let i in initData) {
    if (initData[i] != ruleForm[i]) {
      update[i] = ruleForm[i];
      updateBool = true;
    }
  }
  if (updateBool) {
    update[id] = ruleForm[id];
  }
  return {
    updateBool,
    update,
  };
}

/**
 * @name: 根据传递的值 获取到省市区id
 * @param {*} pcd_id
 * @param {*} regionKeys
 * @return {*}
 *
 * @Date: 2022-06-08 21:24:02
 * @LastEditTime: Do not edit
 */
export function getPcdIds(pcd_id, regionKeys) {
  // 省市区id
  let province_id = 0;
  let city_id = 0;
  let district_id = 0;
  // 获取到省市区id
  if (typeof pcd_id == "number" && pcd_id > 0) {
    const { id, parent_id, region_type } = regionKeys[pcd_id];
    // 省
    if (region_type == 1) {
      province_id = id;
      // 市
    } else if (region_type == 2) {
      province_id = parent_id;
      city_id = id;
      // 区
    } else if (region_type == 3) {
      district_id = id;
      city_id = parent_id;
      province_id = regionKeys[city_id].id;
    }
  }
  return {
    province_id,
    city_id,
    district_id,
  };
}

/**
 * @name: 获取到省市区名称拼接
 * @return {*}
 *
 * @Date: 2022-06-08 21:47:13
 * @LastEditTime: Do not edit
 */
export function getPcdNames(item, regionKeys) {
  let pcd = "";
  if (item.province_id > 0) {
    pcd += regionKeys[item.province_id].region_name;
  }
  if (item.city_id > 0) {
    pcd += regionKeys[item.city_id].region_name;
  }
  if (item.district_id > 0) {
    pcd += regionKeys[item.district_id].region_name;
  }
  return pcd;
}

/**
 * @name: 获取到 对应的层级和父级id 传递
 * @return {*}
 *
 * @Date: 2022-06-09 20:36:45
 * @LastEditTime: Do not edit
 */
const getTreeChildLevel = (pid, tree, level, findLevel = 1) => {
  let returnObj = {
    pObj: {},
    bool: false,
  };
  for (const i in tree) {
    // 如果等于
    if (tree[i]._id == pid) {
      returnObj.pObj = tree[i];
      returnObj.bool = true;
      return returnObj;
    }
    // 如果有子级 且 长度大于0
    if (tree[i].children && tree[i].children.length > 0) {
      const cObj = getTreeChildLevel(pid, tree[i].children, level + 1);
      if (cObj.bool) {
        return cObj;
      }
    }
  }
  return { ...returnObj, level };
};

/**
 * @name: 对数组数据进行转换为 key
 * @return {*}
 *
 * @Date: 2022-06-08 21:47:13
 * @LastEditTime: Do not edit
 */
export function getDataKeys(data, key = "id") {
  const obj = {};
  for (const i in data) {
    obj[data[i][key]] = data[i];
  }
  return obj;
}

/**
 * 冒泡排序
 * @param {*} list 数组
 * @param {*} key 键值
 * @returns
 */
export function bubbleSort(list, key = "sort") {
  for (let i = 0; i < list.length - 1; i += 1) {
    //通过 this.length 次把第一位放到最后,完成排序
    //-i是因为最后的位置是会动态改变的，当完成一次后,最后一位会变成倒数第二位
    for (let j = 0; j < list.length - 1 - i; j += 1) {
      if (typeof list[j][key] == "undefined") {
        list[j][key] = 0;
      }
      if (typeof list[j + 1][key] == "undefined") {
        list[j + 1][key] = 0;
      }
      if (list[j][key] < list[j + 1][key]) {
        const temp = list[j];
        list[j] = list[j + 1];
        list[j + 1] = temp;
      }
    }
  }
  return list;
}

export { getTreeChildLevel };
