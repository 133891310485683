/*
 * @Date: 2022-06-06 09:10:22
 *
 * @LastEditTime: 2022-08-02 10:23:13
 * @FilePath: \iot-admin-ui\src\api\system\common\index.ts
 */
import request from "/@/utils/request";

// 获取到 省市区
export function getRegionList(query: Object | null | undefined) {
  return request({
    // url: '/api/v1/system/dict/data/list',
    url: "/admin/system/common/region/list",
    method: "get",
    params: query,
  });
}

// 获取到 图片列表
export function getImageListApi(query: Object | null | undefined) {
  return request({
    // url: '/api/v1/system/dict/data/list',
    url: "/admin/system/common/image-list",
    method: "get",
    params: query,
  });
}

// 删除 图片
export function deleteImageApi(query: Object | null | undefined) {
  return request({
    // url: '/api/v1/system/dict/data/list',
    url: "/admin/system/common/delete-image",
    method: "post",
    data: query,
  });
}
