/*
 * @Date: 2022-06-08 14:59:51
 *
 * @LastEditTime: 2022-07-13 09:29:57
 * @FilePath: \iot-admin-ui\src\store\modules\rule.ts
 */
import { Module } from "vuex";
import { RuleState, RootStateTypes } from "/@/store/interface/index";

import { getProjectRuleList as getProjectRuleListApi } from "/@/api/iot/project/rule";

// 转换树形数据
import { getTree, getDataKeys } from "/@/utils/changeData.js";

// 转换 树形数据
import {
  showProjectRule as showProjectRuleApi,
  getProjectRuleGroupList as getProjectRuleGroupListApi,
  getProjectRuleCatList as getProjectRuleCatListApi,
  getProjectRuleProductList as getProjectRuleProductListApi,
  getProjectRuleDeviceList as getProjectRuleDeviceListApi,
} from "/@/api/iot/project/rule";

const ruleModule: Module<RuleState, RootStateTypes> = {
  namespaced: true,
  state: {
    time: 300, // 秒 过期

    device_options: [],
    device_options_params: {},
    device_options_api_time: 0,

    product_options: [],
    product_options_params: {},
    product_options_api_time: 0,

    group_options: [],
    group_options_params: {},
    group_options_api_time: 0,

    cat_options: [],
    cat_options_params: {},
    cat_options_api_time: 0,

    rule_options: [],
    rule_options_params: {},
    rule_options_api_time: 0,
  },
  mutations: {
    // 进行设置规则 数据
    getRules(state, data: RuleState) {
      // 设备数据
      if (data.device_options) {
        state.device_options = data.device_options;
      }
      if (data.device_options_params) {
        state.device_options_params = data.device_options_params;
      }
      if (data.device_options_api_time) {
        state.device_options_api_time = data.device_options_api_time;
      }

      // 产品数据
      if (data.product_options) {
        state.product_options = data.product_options;
      }
      if (data.product_options_params) {
        state.product_options_params = data.product_options_params;
      }
      if (data.product_options_api_time) {
        state.product_options_api_time = data.product_options_api_time;
      }

      // 分组数据
      if (data.group_options) {
        state.group_options = data.group_options;
      }
      if (data.group_options_params) {
        state.group_options_params = data.group_options_params;
      }
      if (data.group_options_api_time) {
        state.group_options_api_time = data.group_options_api_time;
      }

      // 分类数据
      if (data.cat_options) {
        state.cat_options = data.cat_options;
      }
      if (data.cat_options_params) {
        state.cat_options_params = data.cat_options_params;
      }
      if (data.cat_options_api_time) {
        state.cat_options_api_time = data.cat_options_api_time;
      }

      // 路由数据
      if (data.rule_options) {
        state.rule_options = data.rule_options;
      }
      if (data.rule_options_params) {
        state.rule_options_params = data.rule_options_params;
      }
      if (data.rule_options_api_time) {
        state.rule_options_api_time = data.rule_options_api_time;
      }
    },
  },
  actions: {
    // 设置 规则列表
    async setRuleOptionsLists({ commit, state }, data: RuleState) {
      return new Promise((resolve, reject) => {
        //resolve,reject是两个系统函数，对应成功和失败
        if (data && data.rule_options && data.rule_options.length > 0) {
          commit("getRules", data);
          resolve(data.rule_options);
        } else {
          const now = Math.floor(new Date().getTime() / 1000) as number;
          const apiTime = state.rule_options_api_time as number;
          // 如果等于0 或者 时间超过了
          if (state.rule_options.length == 0 || now - apiTime > state.time) {
            getProjectRuleListApi(data.rule_options_params).then((res) => {
              const list = [];
              for (const i in res.data.list) {
                list.push({
                  label: res.data.list[i].name,
                  value: res.data.list[i]._id,
                });
              }
              commit("getRules", {
                rule_options: list,
                rule_options_api_time: now,
                rule_options_params: data.rule_options_params,
              });

              // 抛出
              resolve({
                rule_options: list,
              });
            });
          } else {
            resolve(state.rule_options);
          }
        }
      });
    },
    // 设置 分组列表
    async setGroupOptionsLists({ commit, state }, data: RuleState) {
      return new Promise((resolve, reject) => {
        //resolve,reject是两个系统函数，对应成功和失败
        if (data && data.group_options && data.group_options.length > 0) {
          commit("getRules", data);
          resolve(data.group_options);
        } else {
          const now = Math.floor(new Date().getTime() / 1000) as number;
          const apiTime = state.group_options_api_time as number;
          // 如果等于0 或者 时间超过了
          if (state.group_options.length == 0 || now - apiTime > state.time) {
            getProjectRuleGroupListApi(data.group_options_params).then(
              (res) => {
                const tree = getTree(res.data, { id: "_id", pid: "pid" });
                commit("getRules", {
                  group_options: tree,
                  group_options_api_time: now,
                  group_options_params: data.group_options_params,
                });

                // 抛出
                resolve({
                  group_options: tree,
                });
              }
            );
          } else {
            resolve(state.group_options);
          }
        }
      });
    },
    // 设置 分类 列表
    async setCatOptionsLists({ commit, state }, data: RuleState) {
      return new Promise((resolve, reject) => {
        //resolve,reject是两个系统函数，对应成功和失败
        if (data && data.cat_options && data.cat_options.length > 0) {
          commit("getRules", data);
          resolve(data.cat_options);
        } else {
          const now = Math.floor(new Date().getTime() / 1000) as number;
          const apiTime = state.cat_options_api_time as number;
          // 如果等于0 或者 时间超过了
          if (state.cat_options.length == 0 || now - apiTime > state.time) {
            getProjectRuleCatListApi(data.cat_options_params).then(
              (res: any) => {
                const tree = getTree(res.data, { id: "_id", pid: "pid" });
                commit("getRules", {
                  cat_options: tree,
                  cat_options_api_time: now,
                  cat_options_params: data.cat_options_params,
                });

                // 抛出
                resolve({
                  cat_options: tree,
                });
              }
            );
          } else {
            resolve(state.cat_options);
          }
        }
      });
    },

    // 设置 产品 列表
    async setProductOptionsLists({ commit, state }, data: RuleState) {
      return new Promise((resolve, reject) => {
        //resolve,reject是两个系统函数，对应成功和失败
        if (data && data.product_options && data.product_options.length > 0) {
          commit("getRules", data);
          resolve(data.product_options);
        } else {
          const now = Math.floor(new Date().getTime() / 1000) as number;
          const apiTime = state.product_options_api_time as number;
          // 如果等于0 或者 时间超过了
          if (state.product_options.length == 0 || now - apiTime > state.time) {
            getProjectRuleProductListApi(data.product_options_params).then(
              (res: any) => {
                const product_options = [];
                for (const i in res.data) {
                  if (res.data[i].product_id && res.data[i].product_id._id) {
                    product_options.push({
                      value: res.data[i].product_id._id,
                      label: res.data[i].product_id.name,
                    });
                  }
                }
                commit("getRules", {
                  product_options: product_options,
                  product_options_api_time: now,
                  product_options_params: data.product_options_params,
                });

                // 抛出
                resolve({
                  product_options: product_options,
                });
              }
            );
          } else {
            resolve(state.product_options);
          }
        }
      });
    },
  },
};

export default ruleModule;
