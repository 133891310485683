// 定义内容
export default {
  formI18nLabel: {
    name: "姓名",
    email: "用户归属部门",
    autograph: "登陆账户名",
  },
  formI18nPlaceholder: {
    name: "请输入姓名",
    email: "请输入用户归属部门",
    autograph: "请输入登陆账户名",
  },
};
