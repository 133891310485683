/*
 * @Author: zengye 1459584248@qq.com
 * @Date: 2023-06-03 10:17:48
 * @LastEditors: zengye 1459584248@qq.com
 * @LastEditTime: 2023-06-03 10:38:24
 * @FilePath: \iotAdminWeb\src\i18n\index.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { createI18n } from "vue-i18n";
import zhcnLocale from "element-plus/es/locale/lang/zh-cn";
import enLocale from "element-plus/es/locale/lang/en";
import zhtwLocale from "element-plus/es/locale/lang/zh-tw";
import { store } from "/@/store/index";

import nextZhcn from "/@/i18n/lang/zh-cn";
import nextEn from "/@/i18n/lang/en";
import nextZhtw from "/@/i18n/lang/zh-tw";

import pagesLoginZhcn from "/@/i18n/pages/login/zh-cn";
import pagesLoginEn from "/@/i18n/pages/login/en";
import pagesLoginZhtw from "/@/i18n/pages/login/zh-tw";
import pagesFormI18nZhcn from "/@/i18n/pages/formI18n/zh-cn";
import pagesFormI18nEn from "/@/i18n/pages/formI18n/en";
import pagesFormI18nZhtw from "/@/i18n/pages/formI18n/zh-tw";

// 定义语言国际化内容
/**
 * 说明：
 * /src/i18n/lang 下的 ts 为框架的国际化内容
 * /src/i18n/pages 下的 ts 为各界面的国际化内容
 */
const messages = {
  [zhcnLocale.name]: {
    ...zhcnLocale,
    message: {
      ...nextZhcn,
      ...pagesLoginZhcn,
      ...pagesFormI18nZhcn,
    },
  },
  [enLocale.name]: {
    ...enLocale,
    message: {
      ...nextEn,
      ...pagesLoginEn,
      ...pagesFormI18nEn,
    },
  },
  [zhtwLocale.name]: {
    ...zhtwLocale,
    message: {
      ...nextZhtw,
      ...pagesLoginZhtw,
      ...pagesFormI18nZhtw,
    },
  },
};

// 导出语言国际化
export const i18n = createI18n({
  locale: store.state.themeConfig.themeConfig.globalI18n,
  fallbackLocale: zhcnLocale.name,
  messages,
  legacy: false,
});
