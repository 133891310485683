/*
 * @Date: 2022-06-06 09:10:22
 *
 * @LastEditTime: 2022-06-24 22:23:48
 * @FilePath: \iot-admin-ui\src\api\iot\project\rule\index.ts
 */
import request from "/@/utils/request";
import { Session } from "/@/utils/storage";
import qs from "qs";

const time = 60;

// 列表 规则
export function getProjectRuleList(query: Object) {
  /*
    Math.floor(new Date().getTime() / 1000)
    const url = '/admin/project/rule/list';
    const key = url + '?' + qs.stringify(query);
    const list = Session.get(key)
    console.log('列表规则',list);
    */

  return request({
    // url: '/api/v1/system/dict/type/list',
    url: "/admin/project/rule/list",
    method: "get",
    params: query,
  });
}

// 详情 规则详情
export function showProjectRule(data: object) {
  return request({
    // url: '/api/v1/system/dict/type/get',
    url: "/admin/project/rule/show",
    method: "get",
    cache: 10,
    params: data,
  });
}

// 添加 规则
export function addProjectRule(data: any) {
  return request({
    url: "/admin/project/rule/create",
    method: "post",
    data: data,
  });
}

// 编辑 规则
export function editProjectRule(data: any) {
  return request({
    url: "/admin/project/rule/update",
    method: "post",
    data: data,
  });
}

// 复制 规则
export function copyProjectRuleApi(data: any) {
  return request({
    url: "/admin/project/rule/copy",
    method: "post",
    data: data,
  });
}

// 触发 规则
export function triggerProjectRuleApi(data: any) {
  return request({
    url: "/admin/project/rule/trigger",
    method: "post",
    data: data,
  });
}

// 导入模板
export function templateProjectRuleApi(data: any) {
  return request({
    url: "/admin/project/rule/template",
    method: "get",
    params: data,
  });
}

// 编辑详情 规则
export function editProjectDetailRule(data: any) {
  return request({
    url: "/admin/project/rule/update-rule",
    method: "post",
    data: data,
  });
}

// 删除 项目分组
export function deleteProjectRule(data: any) {
  return request({
    url: "/admin/project/rule/delete",
    method: "post",
    data,
  });
}

// 获取到 项目规则 绑定的 分组
export function getProjectRuleGroupList(query: Object) {
  /*
    const now = Math.floor(new Date().getTime() / 1000)
    const url = '/admin/project/rule/project-group-list';
    const key = url + '?' + qs.stringify(query);
    const list = Session.get(key)
    if (now - list.api_time <= (time ?? 300)) {
        console.log(now ,new Date().toLocaleString(),list, '回显成功')
        return new Promise((resolve,reject) => {
            resolve(list);
        })
    }
    */

  return request({
    // url: '/api/v1/system/dict/type/list',
    url: "/admin/project/rule/project-group-list",
    method: "get",
    params: query,
  });
}

// 获取到 项目规则 绑定的 分类
export function getProjectRuleCatList(query: Object) {
  return request({
    // url: '/api/v1/system/dict/type/list',
    url: "/admin/project/rule/project-cat-list",
    method: "get",
    params: query,
  });
}

// 获取到 项目规则 绑定的 产品
export function getProjectRuleProductList(query: Object) {
  return request({
    // url: '/api/v1/system/dict/type/list',
    url: "/admin/project/rule/project-product-list",
    method: "get",
    params: query,
  });
}

// 获取到 项目规则 绑定的 设备
export function getProjectRuleDeviceList(query: Object) {
  return request({
    // url: '/api/v1/system/dict/type/list',
    url: "/admin/project/rule/project-device-list",
    method: "get",
    params: query,
  });
}
